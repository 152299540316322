body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/images/ninjas.png);
  background-size: cover;
  background-repeat: no-repeat;
  
  /* min-height: 940px; */
}
@media only screen and (max-width: 760px) {
  .fadeshow {
      display: none;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html{
  height:100%;
  overflow-x:hidden;
  
    
}
/* .bg{
  height:900px;
} */
.App{
    grid-column-start: 2;
    grid-row-start:2;
    /* height: 100%;
    width: 100%; */
    /* border: solid;
    color: white; */
    margin: 9px;
    /* background-image: url('/images/ninjas.png');
    background-size: 100% 100%; */
    
}
.standings{
  text-Align:'left';
  padding:15px;
  /* grid-column-start: 2;
  grid-column-end: 3; 
  grid-row-start: 2; */
  /* border:solid;
  color:white;   */
  /* min-width: 590px; */
  
}
.rawdata{
  text-Align:'left';
  padding:15px;
  /* grid-column-start: 3;
  grid-column-end: 4; 
  grid-row-start: 2; */
  /* border:solid;
  color:white; */
  
}
.firstRow{
  grid-column-start: 1;
  grid-column-end: 6;
}

.standingsGrid{
  display: grid;
  padding:5px;
  /* grid-template-rows: 1fr 1fr; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
}
.rawdataGrid{
  display: grid;
  padding:5px;
  /* grid-template-rows: 1fr 1fr; */
  /* grid-template-columns: 1fr 1fr; */
}
.standingsRow{
  text-align: left;
  /* margin-left: 5px; */
  /* padding-left: 5px; */
  width: 100px;
  font-weight: bold;
  color:white;
}
.standingsTitle{
  grid-column-start: 1;
  grid-column-end: 8;
  color:white;
}
.rawdataTitle{
  grid-column-start: 1;
  grid-column-end: 3;
  color:white;
}
.badplayer{
  background-color:#ff000045;
}
/* #root{
  display: grid;
    grid-template-columns: 10vw 80vw 10vw;
    grid-template-rows: 10vh 80vh 10vh;
   
} */
.paper{
  padding: 20px;
  text-align: 'left';
  background-color: grey;
  white-space: nowrap;
  /* margin-bottom: 1px; */
  margin:9px;  
  
}
#paper{
  background-color: grey;
}

#highlight{
  background-color: green;
  padding:25px;
}

.gridcont{
  margin: 9px;
}
.competitions{
  padding:15px;
  /* text-Align:'left'; */
  /* grid-column-start: 2;
  grid-column-end: 3; 
  grid-row-start: 2; */
  /* border:solid;
  color:white;   */
  /* min-width: 590px; */
  
}
.competitionsGrid{
  display:grid;
}
.competitionsTitle{
  /* grid-column-start: 1;
  grid-column-end: 8; */
  color:white;
}
.competitionsRow{
  text-align: left;
  /* margin-left: 5px; */
  /* padding-left: 5px; */
  /* width: 125px; */
  font-weight: bold;
  color:white;
}
.fab{
  position: absolute;
  bottom: 0;
  
}
#AddButton{
  position: fixed;
  bottom:2rem;
  right:2rem;

}
.check{
  color: green;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.leftAlign{
  text-align: left;
  margin:9px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.demo-image-preview {
  position: relative;
  text-align: center;
}

.demo-image-preview > img {
  width: 768px;
}

@media(max-width:768px){
  .demo-image-preview > img {
    width: 100%;
  }
}

/* fullscreen enable by props */
.demo-image-preview-fullscreen > img {
  width: 100vw;
  height:100vh;
}

